import React from "react"
import Navbar from "../../components/navbar"
import Container from "../../components/container"
import Header from "../../components/header"
import SubHeader from "../../components/subHeader"
import Layout from "../../components/layout"
import BackgroundShape from "../../components/backgroundShape"
import Head from "../../components/head"
import SectionLayout from "../../components/sectionLayout"
import Footer from "../../components/new/footer/footer"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"


export default function Partners () {

    return (
	<div className="">
        <Head title="Join the Onport Partner Ecosystem Now" description="Join the dozens of solutions which have already built a robust and lasting relationship with Onport"/>
        <Navbar style={{classes: "position-absolute bg-transparent"}}/>

		<Container style={{classes: "container-fluid bg-contact-lg py-5" }}>
			<Container style={{classes: "container" }}>
			<SubHeader content={{title: "Become an Onport Partner", text:"With Onport, partners are free to build, customize, and extend the platform to create amazing digital experiences for your individual clients or to support your own packaged solution. Join our partnership program and see the results for yourself today."}} style={{color: " text-center w-75 mx-auto text-white"}}/>
				<div className="">
    				<HubspotContactFormPartner id='63a4f934-62b0-4ff2-b126-9f97421729fb'/>
				</div>
    		</Container>	
        </Container>
		<Footer style="mt-0" />
    </div>	
    )
}